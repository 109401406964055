<template>
  <div>
    <div class="shenhebox">
      <div class="shenhe">
        审核意见：
        <div class="mp20">
          <span>选择照片：</span
          ><el-button
            type="primary"
            size="mini"
            icon="el-icon-plus"
            @click="open()"
            >选择照片</el-button
          >
        </div>
        <div>

        </div>
        <div class="mp20 flex">
          <div class="bold" style="width: 100px">审核意见：</div>
          <el-input
            type="textarea"
            placeholder="请输入审核意见"
            v-model="obj.auditOpinion"
          ></el-input>
        </div>
      </div>
    </div>

    <el-dialog
      title="提示"
      :visible.sync="dialogVisible"
      width="30%"
      append-to-body
    >

    </el-dialog>

  </div>
</template>

<script>
export default {
  data() {
    return {
      obj: {
        auditOpinion: "",
      },
      dialogVisible:false,
      imgList:[]
    };
  },
  methods: {
    open() {
      this.dialogVisible = true
    },
  },
};
</script>

<style scoped>
.shenhe {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
}
</style>
