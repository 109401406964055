<template>
  <div>
    <el-drawer
      title="退货详情"
      :visible.sync="drawer"
      direction="rtl"
      size="60%"
      :before-close="handleClose"
      
    >
      <div class="drawer">
        <div class="left">
          <div class="left-header">
            <el-row>
              <el-col :span="12">
                <span class="bold">退货单号：</span>
                {{ obj.returnCode }}</el-col
              >
              <el-col :span="12"
                ><span class="bold">申请人：</span>{{ obj.applyUser }}</el-col
              >
              <div style="margin-top: 30px">
                <el-col :span="12"
                  ><span class="bold">户　　主：</span
                  >{{ obj.ownerUser }}</el-col
                >
                <el-col :span="12"
                  ><span class="bold">地　址：</span>{{ obj.address }}</el-col
                >
              </div>
            </el-row>
            <div class="text">退货信息</div>
          </div>
          <div class="left-content">
            <div class="left-content-box">
              <div>
                <!-- 基本信息 -->
                <div>
                  <div class="left-content-title">
                    <div style="margin-left: 10px">基本信息</div>
                  </div>
                  <el-row class="row20">
                    <el-col :span="12">
                      <span class="bold">申请人：</span
                      ><span>{{ obj.applyUser }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="bold">申请日期：</span
                      ><span>{{ obj.createTime }}</span>
                    </el-col>
                  </el-row>

                  <el-row class="row20">
                    <el-col :span="12">
                      <span class="bold">代理商名称：</span
                      ><span>{{ obj.baseInfo.agentName }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="bold">联系人：</span
                      ><span>{{ obj.baseInfo.agentContact }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="12">
                      <span class="bold">电话：</span
                      ><span>{{ obj.phone }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="bold">逆变器SN：</span
                      ><span>{{ obj.baseInfo.reverseSn }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="12">
                      <span class="bold">户主：</span
                      ><span>{{ obj.ownerUser }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="bold">项目公司名称：</span
                      ><span>{{ obj.baseInfo.projeCompanName }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="12">
                      <span class="bold">并网时间：</span
                      ><span>{{ obj.baseInfo.mergeTime }}</span>
                    </el-col>
                    <el-col :span="12">
                      <span class="bold">建议发货方式：</span
                      ><span>{{ obj.baseInfo.adviceDeliveryType }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="24">
                      <span class="bold">地址：</span
                      ><span>{{ obj.address }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="24">
                      <span class="bold">代理商审核日期：</span
                      ><span>{{ obj.baseInfo.agentApplyTime }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="24">
                      <span class="bold">总部审核日期：</span
                      ><span>{{ obj.baseInfo.baseApplyTime }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="24">
                      <span class="bold">申请备注：</span
                      ><span>{{ obj.baseInfo.applyRemarks }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="24">
                      <span class="bold">代理商审核备注：</span
                      ><span>{{ obj.baseInfo.agentApplyRemarks }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="24">
                      <span class="bold">总部审核备注：</span
                      ><span>{{ obj.baseInfo.baseApplyRemarks }}</span>
                    </el-col>
                  </el-row>
                  <el-row class="row20">
                    <el-col :span="14">
                      <div class="boxx">
                        <el-row>
                          <el-col :span="24">{{
                            obj.baseInfo.productName
                          }}</el-col>
                        </el-row>
                        <el-row>
                          <el-col :span="12"
                            ><span class="label">品牌：</span
                            ><span style="color: #ccc">{{
                              obj.baseInfo.productBrand
                            }}</span></el-col
                          >
                          <el-col :span="12"
                            ><span class="label">型号：</span
                            ><span style="color: #ccc">{{
                              obj.baseInfo.productType
                            }}</span></el-col
                          >
                        </el-row>
                        <el-row>
                          <el-col :span="12"
                            ><span class="label">功率：</span
                            ><span style="color: #ccc">{{
                              obj.baseInfo.power
                            }}</span></el-col
                          >
                          <el-col :span="12"
                            ><span class="label">数量：</span
                            ><span style="color: #ccc">{{
                              obj.baseInfo.productNum
                            }}</span></el-col
                          >
                        </el-row>
                        <el-row>
                          <el-col :span="12"
                            ><span class="label">初始库存：</span
                            ><span style="color: #ccc">{{
                              obj.baseInfo.nowLeft
                            }}</span></el-col
                          >
                          <el-col :span="12"
                            ><span class="label">入库后库存：</span
                            ><span style="color: #ccc">{{
                              obj.baseInfo.afterLeft
                            }}</span></el-col
                          >
                        </el-row>
                      </div>
                    </el-col>
                  </el-row>
                </div>
                <!-- 图片信息 -->
                <div>
                  <div class="left-content-title">
                    <div style="margin-left: 10px">图片信息</div>
                  </div>
                  <div>
                    <div>
                      <div>组件背面铭牌照片</div>
                      <div class="flexxxx">
                        <div
                          v-for="(item, index) in obj.pics.backPicsList"
                          :key="index"
                        >
                          <img class="imgs" :src="item.imageUrl" alt="" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>组件正面铭牌照片</div>
                      <div class="flexxxx">
                        <div
                          v-for="(item, index) in obj.pics.frontPicsList"
                          :key="index"
                        >
                          <img class="imgs" :src="item.imageUrl" alt="" />
                        </div>
                      </div>
                    </div>

                    <div>
                      <div>组件整体图片</div>
                      <div class="flexxxx">
                        <div
                          v-for="(item, index) in obj.pics.wholePicsList"
                          :key="index"
                        >
                          <img class="imgs" :src="item.imageUrl" alt="" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>故障部位局部照片</div>
                      <div class="flexxxx">
                        <div
                          v-for="(item, index) in obj.pics.damegePicsList"
                          :key="index"
                        >
                          <img class="imgs" :src="item.imageUrl" alt="" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>外包装照片（如有）</div>
                      <div class="flexxxx">
                        <div
                          v-for="(item, index) in obj.pics.packagePicsList"
                          :key="index"
                        >
                          <img class="imgs" :src="item.imageUrl" alt="" />
                        </div>
                      </div>
                    </div>
                    <div>
                      <div>故障排除视频</div>
                      <div class="flexxxx">
                        <div v-for="item in obj.pics.videoList" :key="item">
                          <video class="imgs" :src="item" alt="" />
                        </div>
                      </div>
                    </div>
                    <div>其他：暂无</div>
                  </div>
                </div>
                <!-- 发货信息 -->
                <div>
                  <div class="left-content-title">
                    <div style="margin-left: 10px">发货信息</div>
                  </div>
                  <div class="mp20 pd20">
                    <div class="mp20">
                      <span>快递公司</span> : <span>跨越</span>
                    </div>
                    <div class="mp20">
                      <span>快递单号</span> : <span>跨越</span>
                    </div>
                    <div class="mp20">
                      <span>发货日期</span> : <span>跨越</span>
                    </div>
                    <div class="mp20">
                      <span>新品序列号</span> : <span>跨越</span>
                    </div>
                    <div class="mp20">
                      <span>备注</span> : <span>跨越</span>
                    </div>
                  </div>
                </div>
                <!-- 收获确认 -->
                <div>
                  <div class="left-content-title">
                    <div style="margin-left: 10px">收货确认</div>
                  </div>
                  <div class="mp20 pd20">
                    <div class="mp20">
                      <span>签收日期</span> : <span>测试</span>
                    </div>
                    <div class="mp20">
                      <span>签收人</span> : <span>张三三</span>
                    </div>
                    <div class="mp20">
                      <span>签收照片</span> :
                      <img
                        src=""
                        class="imgs"
                        style="vertical-align: top"
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="left-bottom">
              <div class="mp20 flex">
                <span>审核结果</span> :
                <div
                  style="margin-left: 15px"
                  :class="currentIndex == 0 ? 'btn active ' : 'btn'"
                  @click="currentIndex = 0"
                >
                  通过
                </div>
                <div
                  :class="currentIndex == 1 ? 'btn active ' : 'btn'"
                  @click="currentIndex = 1"
                >
                  不通过
                </div>
              </div>
              <div class="mp20">
                <span>审核意见</span> :
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-plus"
                  @click="addAudit"
                  >添加审核意见</el-button
                >

                <!-- <div class="shenhebox">
                  <div
                    class="shenhe"
                    v-for="(item, index) in auditList"
                    :key="index"
                  >
                    审核意见{{ index + 1 }}：
                    <div class="mp20">
                      <span>选择照片：</span
                      ><el-button
                        type="primary"
                        size="mini"
                        icon="el-icon-plus"
                        @click="open(index)"
                        >选择照片</el-button
                      >
                    </div>
                    <div class="mp20 flex">
                      <div class="bold" style="width: 100px">审核意见：</div>
                      <el-input
                        type="textarea"
                        placeholder="请输入审核意见"
                        v-model="item.auditOpinion"
                      ></el-input>
                    </div>
                  </div>
                </div> -->
                <div v-for="(component, index) in components" :key="index">
                  <component :is="component"></component>
                </div>
                <div>
                  <Auditing></Auditing>
                </div>
                <el-row class="mp20" type="flex" justify="center">
                  <el-button
                    type="primary"
                    icon="el-icon-plus"
                    @click="addAuditResult"
                    >提交审核结果</el-button
                  >
                </el-row>
              </div>
            </div>
          </div>
        </div>
        <div class="right">
          <div>历史记录</div>
          <div style="height: 500px; margin: 50px 30px">
            <el-steps direction="vertical" :active="3">
              <el-step title="步骤 1" description="12312321"></el-step>
              <el-step title="步骤 2" description="12312321"></el-step>
              <el-step title="步骤 3" description="12312321"></el-step>
            </el-steps>
          </div>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script>
import Auditing from "./Auditing.vue";
export default {
  components: { Auditing },
  props: {
    obj: {
      typeof: Object,
      default: () => ({}),
    },
    drawer: {
      typeof: Boolean,
      default: false,
    },
    dialogVisible: {
      typeof: Boolean,
      default: false,
    },
    dialogVisible2: {
      typeof: Boolean,
      default: false,
    },
    dialogVisible3: {
      typeof: Boolean,
      default: false,
    },
    imgList: {
      typeof: Array,
      default: [],
    },
  },
  data() {
    return {
      // auditList: [
      //   {
      //     auditOpinion: "",
      //     imgList: [],
      //   },
      // ],
      components: [], // 存储动态组件
      reverse: true,
      currentIndex: 0,
      activities: [
        {
          content: "活动按期开始",
          timestamp: "2018-04-15",
        },
        {
          content: "通过审核",
          timestamp: "2018-04-13",
        },
        {
          content: "流程开始",
          timestamp: "2018-04-11",
        },
      ],
    };
  },
  methods: {
    addAudit() {
      this.components.push("Auditing");
    },
    addAuditResult() {
      console.log(this.auditList);
      // let data = {
      //   imgList: this.imgList,
      //   currentIndex: this.currentIndex,
      //   auditOpinion: this.auditOpinion,
      // };
      // console.log(data);
    },
    open(index) {
      // if (index + 1 == 1) {
      //   this.dialogVisible = true;
      //   this.$emit("open", this.dialogVisible);
      // } else if (index + 1 == 2) {
      //   this.dialogVisible2 = true;
      //   this.$emit("open2", this.dialogVisible2);
      // } else if (index + 1 == 3) {
      //   this.dialogVisible3 = true;
      //   this.$emit("open3", this.dialogVisible3);
      // }
    },
    handleClose() {
      this.drawer = false;
      this.$emit("closeDrawer", this.drawer);
    },
    // selectPic(item) {
    //   console.log(item, 407);
    // },
  },
};
</script>

<style lang="scss">
.drawer {
  display: flex;
  width: 100%;
  height: 100vh;
  .left {
    width: 65%;
    // background-color: pink;
    border-right: 1px solid #f2f2f2;
    border-top: 1px solid #ccc;
    .left-header {
      padding: 20px;
      box-sizing: border-box;
      background-color: #fff;
      .text {
        margin-top: 30px;
        color: #3a6def;
        font-weight: bold;
        border-bottom: 2px solid #3a6def;
        width: 70px;
      }
    }
    .left-content {
      background-color: #f2f2f2;
      font-size: 14px;
      padding: 15px;
      height: calc(100vh - 210px);
      box-sizing: border-box;
      border-right: 1px solid #f2f2f2;
      overflow: auto;
      .left-content-box {
        width: 100%;
        background-color: #fff;
        height: 100%;
        border-radius: 5px;
        overflow: auto;
        padding: 20px;
        box-sizing: border-box;
        .left-content-title {
          border-left: 3px solid #2e64ee;
          margin-top: 15px;
          font-weight: bold;
        }
      }
      .left-bottom {
        width: 100%;
        min-height: 350px;
        margin-top: 20px;
        border-radius: 5px;
        background-color: #fff;
        padding: 30px;
        box-sizing: border-box;
      }
    }
  }
  .right {
    border-top: 1px solid #ccc;
    width: 35%;
    text-align: center;
    padding-top: 30px;
    box-sizing: border-box;
    // background-color: red;
  }
}
.row20 {
  margin-top: 10px;
}
.mp20 {
  margin-top: 10px;
}
.pd20 {
  padding: 0 20px;
  box-sizing: border-box;
}
.boxx {
  width: 100%;
  height: 150px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .label {
    display: inline-block;
    width: 100px;
    text-align: end;
  }
}
.imgs {
  width: 130px;
  height: 130px;
  margin: 10px;
}
.flexxxx {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.flex2 {
  display: flex;
  justify-content: space-between;
  width: 500px;
  text-align: center;
}
.bold {
  // font-weight: bold;
}

.shenhe {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
}

.btn {
  width: 100px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  border-radius: 5px;
  cursor: pointer;
  margin-right: 10px;
  background-color: #f2f2f2;
  color: #333;
  &.active {
    background-color: #3a6def;
    color: #fff;
  }
}
</style>
