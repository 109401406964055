<template>
  <div class="container bgblue">
    <div class="header">
      <el-row>
        <el-form :model="form" class="demo-form-inline" label-width="80px">
          <el-col :span="6">
            <el-form-item label="退货单号:">
              <el-input
                v-model="form.returnCode"
                placeholder="请输入退货单号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="申请人:">
              <el-input
                v-model="form.creatot"
                placeholder="请输入申请人"
              ></el-input>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="户主:">
              <el-input
                v-model="form.projeCompanName"
                placeholder="请输入户主"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="问题部件:">
              <el-input
                v-model="form.productSpec"
                placeholder="请输入问题部件"
              ></el-input> </el-form-item
          ></el-col>
          <el-col :span="6">
            <el-form-item label="申请日期:">
              <el-date-picker
                v-model="form.date"
                type="datetimerange"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd"
                :default-time="['00:00:00', '23:59:59']"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label-width="30px">
              <el-button type="primary" icon="el-icon-search" @click="onSubmit"
                >查询</el-button
              >
              <el-button icon="el-icon-refresh" @click="onSubmit"
                >重置</el-button
              >
            </el-form-item>
          </el-col>
        </el-form>
      </el-row>
    </div>
    <div class="table">
      <div>
        <!-- <el-button type="primary" icon="el-icon-plus" @click="openDialog"
          >新增退货</el-button
        > -->
      </div>
      <el-table :data="tableData" style="width: 100%" border height="94%">
        <el-table-column prop="" align="center" label="操作" width="120">
          <template v-slot="{ row }">
            <el-button type="text" @click="detail(row)" class="btn-blue">详情</el-button>
            <!-- <el-button type="text">详情2</el-button> -->
            <el-button type="text"  v-if="row.returnStatus !=0" class="btn-red" >审核</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="returnCode"
          align="center"
          label="退换货单号"
        ></el-table-column>
        <el-table-column prop="returnStatus" align="center" label="退货状态">
          <template v-slot="{ row }">
            <el-button  v-if="row.returnStatus == 0" type="text" class="btn-green">已完成</el-button>
            <el-button  v-if="row.returnStatus == 1" type="text" class="btn-orange">已提交</el-button>
            <el-button   v-if="row.returnStatus == 2" type="text" class="btn-red">已拒绝</el-button>
          </template>
        </el-table-column>
        <el-table-column
          prop="applyUser"
          align="center"
          label="申请人"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="ownerUser"
          label="户主"
          width="180"
        >
        </el-table-column>
        <el-table-column align="center" prop="problemParts" label="问题部件">
        </el-table-column>
        <el-table-column align="center" prop="productSpec" label="问题部位">
        </el-table-column>
        <el-table-column align="center" prop="reverseSn" label="逆变器SN">
        </el-table-column>
        <el-table-column align="center" prop="agentName" label="分公司">
        </el-table-column>
      </el-table>
      <div class="page">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="params.currPage"
          background
          :page-size="params.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="totol"
        >
        </el-pagination>
      </div>
    </div>

    <returnCom
      ref="com"
      :obj="obj"
      :drawer="drawer"
      @open="open"
      :dialogVisible="dialogVisible"
      :dialogVisible2="dialogVisible2"
      :dialogVisible3="dialogVisible3"
      @closeDrawer="closeDrawer"
      :imgList="imgList"
    ></returnCom>

    <el-dialog
      title="选择图片1"
      :visible="dialogVisible"
      width="30%"
      class="dialogimg"
      :before-close="handleClose"
    >
      <div class="dialog">
        <div>
          <div>
            <div style="margin: 20px 0 10px 0">组件背面铭牌照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.backPicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">组件正面铭牌照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.frontPicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div>
            <div style="margin: 20px 0 10px 0">故障部位局部照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.damegePicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">外包装照片（如有）</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.packagePicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">故障排除视频</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.videoList"
                :key="index"
                @click="selectPic(item)"
              >
                <video
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-row type="flex" justify="center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveImg">确 定</el-button>
      </el-row>
    </el-dialog>
    <el-dialog
      title="选择图片2"
      :visible="dialogVisible3"
      width="30%"
      class="dialogimg"
      :before-close="handleClose"
    >
      <div class="dialog">
        <div>
          <div>
            <div style="margin: 20px 0 10px 0">组件背面铭牌照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.backPicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">组件正面铭牌照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.frontPicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div>
            <div style="margin: 20px 0 10px 0">故障部位局部照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.damegePicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">外包装照片（如有）</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.packagePicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">故障排除视频</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.videoList"
                :key="index"
                @click="selectPic(item)"
              >
                <video
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-row type="flex" justify="center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveImg">确 定</el-button>
      </el-row>
    </el-dialog>
    <el-dialog
      title="选择图片3"
      :visible="dialogVisible2"
      width="30%"
      class="dialogimg"
      :before-close="handleClose"
    >
      <div class="dialog">
        <div>
          <div>
            <div style="margin: 20px 0 10px 0">组件背面铭牌照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.backPicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">组件正面铭牌照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.frontPicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div>
            <div style="margin: 20px 0 10px 0">故障部位局部照片</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.damegePicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">外包装照片（如有）</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.packagePicsList"
                :key="index"
                @click="selectPic(item)"
              >
                <img
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
          <div>
            <div style="margin: 20px 0 10px 0">故障排除视频</div>
            <div class="flexxxx">
              <div
                v-for="(item, index) in obj.pics.videoList"
                :key="index"
                @click="selectPic(item)"
              >
                <video
                  :class="item.seq == 0 ? 'imgs' : 'imgs bd'"
                  :src="item.imageUrl"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <el-row type="flex" justify="center">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveImg">确 定</el-button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import city from "../../assets/js/city";
import returnCom from "./components/returnCom.vue";
export default {
  components: { returnCom },
  data() {
    return {
      tableData: [{}],
      detailList: [], //详情表格
      obj: {
        baseInfo: {},
        pics: {},
      }, //详情对象
      dialogVisible: false,
      dialogVisible2: false,
      dialogVisible3: false,
      drawer: false, // 抽屉
      form: {
        date: "",
      },
      params: {
        count: 0,
        currPage: 1,
        pageCount: 0,
        pageSize: 10,
      },
      totol: 0,
      options: city,
      optionProps: {
        value: "label",
        label: "label",
      },
      imgList: [],
    };
  },
  methods: {
    onSubmit() {},

    async getData() {
      let condition = {};
      const {
        data: { data },
      } = await this.$http.post("/houseStoreReturn/list", {
        condition,
        ...this.params,
      });
      this.totol = data.count;
      // this.tableData = data.data;
    },
    // 查看明细
    async handelDetail(row) {},

    handleSizeChange(val) {},
    handleCurrentChange(val) {},
    changeCity(val) {
      console.log(val.join(""));
    },
    handleClose() {
      this.dialogVisible = false;
    },
    closeDrawer(val) {
      console.log(val, 302);
      this.drawer = val;
    },
    open(val) {
      this.dialogVisible = val;
    },
    selectPic(item) {
      item.seq == 1 ? (item.seq = 0) : (item.seq = 1);
    },
    detail(row) {
      this.drawer = true;
      this.$http
        .post("/houseStoreReturn/queryOne", { returnCode: row.returnCode })
        .then((res) => {
          this.obj = res.data.data;
        });
    },
    saveImg() {
      const res = this.obj.pics.backPicsList.filter((item) => item.seq == 1);
      const res2 = this.obj.pics.frontPicsList.filter((item) => item.seq == 1);
      const res3 = this.obj.pics.damegePicsList.filter((item) => item.seq == 1);
      const res4 = this.obj.pics.packagePicsList.filter(
        (item) => item.seq == 1
      );
      this.imgList = [...res, ...res2, ...res3, ...res4];
      this.dialogVisible = false;
    },
    audit() {
      console.log(this.$refs.com, 321);
      let data = {
        imgList: this.imgList,
      };
    },
  },
  created() {
    this.getData();
  },
};
</script>

<style lang="scss" scoped>
.container {
  padding: 5px;
  box-sizing: border-box;
  ::v-deep .el-table .cell{
    line-height: 15px !important;
  }
  ::v-deep .el-select {
    width: 100% !important;
  }
  ::v-deep .el-input__inner {
    width: 100% !important;
  }
  ::v-deep .el-table{
    margin-top: 0px !important;
  }
  ::v-deep .el-form-item__label{
    font-size: 13px;


  }
  .header {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    padding-bottom: 0px !important;
    padding: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 10px #ccc;
  }
  .table {
    width: 100%;
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    box-sizing: border-box;
    margin-top: 10px;
       height: calc(100vh - 200px);
    ::v-deep .el-table td {
      padding: 0px 0 !important;
    }
  }
  .page {
    width: 100%;
    text-align: end;
  }
  .dialog {
    ::v-deep .el-button {
      height: 35px !important;
    }
  }
}

.row20 {
  margin-top: 20px;
}
.mp20 {
  margin-top: 20px;
}
.pd20 {
  padding: 0 20px;
  box-sizing: border-box;
}
.boxx {
  width: 100%;
  height: 150px;
  border: 1px solid #f2f2f2;
  border-radius: 5px;
  padding: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  .label {
    display: inline-block;
    width: 100px;
    text-align: end;
  }
}
.imgs {
  width: 130px;
  height: 130px;
  margin: 10px;
}

.bd {
  border: 2px solid #36c6e0;
}

.flex {
  display: flex;
  justify-content: space-between;
  width: 500px;
}
.flexxxx {
  display: flex;
  // justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}
.bold {
  font-weight: bold;
}

.shenhe {
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #f2f2f2;
  margin-top: 20px;
}
.dialog {
  max-height: 500px; /* 设置最大高度 */
  overflow-y: auto; /* 设置垂直滚动条 */
}

  ::v-deep
  .el-table--enable-row-hover
  .el-table__body
  tr:hover
  > td {
  background-color: #f59a23 !important;
}
</style>
